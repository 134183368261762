<template>
	<div id="app">
		<!-- <div style="position: fixed;top:0;zIndex:1000;width:100vw;height:100vh;background:#000;"></div> -->
		<v-Header :headerType='headerType'></v-Header>
		<router-view/>
	</div>
</template>

<script>
  export default {
	watch: {
		$route: {
			handler: function(val, oldVal){
				if (val.name == 'nftDetail') {
					localStorage.setItem('tonftdetailname',oldVal.name)
				}
				if (val.name == 'Mint' || val.name == 'Staking' || val.name == 'Marketplace' || val.name == 'MyNFT' || val.name == 'nftDetail' ) {
					this.headerType = 1
				} else {
					this.headerType = 0
				}
			},
			deep: true
		}
	},
    data(){
        return {
			headerType: 0,
			footerShow: false,
        }
    },
    mounted(e){
        var userAgent = navigator.userAgent
        if (userAgent.indexOf("Firefox") > -1) {
            $('#app').css( 'width','calc( 100vw - 15px)' )
        }
    }
  }
</script>

<style lang="scss"></style>
