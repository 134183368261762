<template>
    <div class="Header">
        <div class="center-web">
            <div class="header-logo-box">
                <router-link to="/">
                    <img src="@/assets/logos/headerLogo.png" alt="">
                </router-link>
            </div>
            <div class="header-nav-box" v-if="headerType == 0">
                <ul>
                    <li @click="goSWAP">SWAP</li>
                    <li @click="goPOOL">POOL</li> 
                    <router-link tag="li" :class="routerType == 1 ? 'active' : ''" to="/Farm">
                        FARM
                        <p class="border"></p>
                    </router-link>
                    <router-link tag="li" :class="routerType == 1 ? 'active' : ''" to="/Mint">
                        NFT
                        <p class="border"></p>
                    </router-link>
                    <router-link tag="li" :class="routerType == 1 ? 'active' : ''" to="/Spacebase">
                        SPACEBASE
                        <p class="border"></p>
                    </router-link>
                    <router-link tag="li" :class="routerType == 1 ? 'active' : ''" to="/xSPACE">
                        XSPACE
                        <p class="border"></p>
                    </router-link>
                    <router-link tag="li" :class="routerType == 1 ? 'active' : ''" to="/Launchpad">
                        LAUNCHPAD
                        <p class="border"></p>
                    </router-link>
                    <router-link tag="li" :class="routerType == 1 ? 'active' : ''" to="/Bridge">
                        BRIDGE
                        <p class="border"></p>
                    </router-link>
                    <router-link tag="li" :class="routerType == 1 ? 'active' : ''" to="/Points">
                        POINTS
                        <p class="border"></p>
                    </router-link>
                </ul>
            </div>
            <div class="header-nav-box" v-if="headerType == 1">
                <ul>
                    <router-link tag="li" :class="routerType == 1 ? 'active' : ''" to="/Mint">
                        MINT
                        <p class="border"></p>
                    </router-link>
                    <router-link tag="li" :class="routerType == 1 ? 'active' : ''" to="/Staking">
                        STAKING
                        <p class="border"></p>
                    </router-link>
                    <router-link tag="li" :class="routerType == 1 ? 'active' : ''" to="/Marketplace">
                        MARKETPLACE
                        <p class="border"></p>
                    </router-link>
                    <router-link tag="li" :class="routerType == 1 ? 'active' : ''" to="/MyNFT">
                        MY NFT
                        <p class="border"></p>
                    </router-link>
                </ul>
            </div>
            <div class="header-connet-box">
                <div class="network">
                    <img @click="showNetwork = !showNetwork" src="@/assets/logos/changeNetwork-blast.png" alt="">
                    <div class="closeNetworklist" v-if="showNetwork" @click="showNetwork = false"></div>
                    <div class="networklist" v-if="showNetwork" :style="{right:(userAddress && walletNetwork != chainId ? '50px' : '')}">
                        <h1>Select a network</h1>
                        <div class="box">
                            <ul class="list">
                                <li>
                                    <p>
                                        <img src="../assets/Header/blast.png" alt="">
                                        Blast Sepolia
                                    </p>
                                    <div class="select selectYes">
                                        <i class="el-icon-check"></i>
                                    </div>
                                </li>
                                <!-- <li @click="gozkSyncSepolia">
                                    <p>
                                        <img src="../assets/Header/zksync.png" alt="">
                                        zkSync Sepolia
                                    </p>
                                    <div class="select">
                                        <i class="el-icon-check"></i>
                                    </div>
                                </li>
                                <li style="display:none;">
                                    <p>
                                        <img src="../assets/Header/evmos.png" alt="">
                                        Evmos
                                    </p>
                                    <div class="select">
                                        <i class="el-icon-check"></i>
                                    </div>
                                </li>
                                <li style="display:none;">
                                    <p>
                                        <img src="../assets/Header/scroll.png" alt="">
                                        Scroll
                                    </p>
                                    <div class="select">
                                        <i class="el-icon-check"></i>
                                    </div>
                                </li> -->
                            </ul>
                        </div>
                        <div class="box">
                            <p class="show" @click="networkBottomList = !networkBottomList"><span>Mainnet</span><i :class="!networkBottomList ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i></p>
                            <ul v-if="networkBottomList" class="list">
                                <li @click="goBlastMainnet">
                                    <p>
                                        <img src="../assets/Header/blast.png" alt="">
                                        Blast 
                                    </p>
                                    <div class="select ">
                                        <i class="el-icon-check"></i>
                                    </div>
                                </li>
                                <!-- <li @click="goOneUrl">
                                    <p>
                                        <img src="../assets/Header/zksync.png" alt="">
                                        zkSync Era
                                    </p>
                                    <div class="select">
                                        <i class="el-icon-check"></i>
                                    </div>
                                </li> -->
                                <!-- <li @click="goOneUrl">
                                    <p>
                                        <img src="../assets/Header/evmos.png" alt="">
                                        Evmos
                                    </p>
                                    <div class="select">
                                        <i class="el-icon-check"></i>
                                    </div>
                                </li>
                                <li @click="goOneUrl">
                                    <p>
                                        <img src="../assets/Header/scroll.png" alt="">
                                        Scroll
                                    </p>
                                    <div class="select">
                                        <i class="el-icon-check"></i>
                                    </div>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="connect">
                    <span v-if="userAddress && walletNetwork != chainId" @click="headerMessageShow = true;messageType = 'switchWalletNetwork'">Switch</span>
                    <span v-if="userAddress == ''" @click="connect">CONNECT</span>
                    <span v-if="userAddress && walletNetwork == chainId" @click="showUserInfo">{{ filtersUsrAddr(userAddress) }} <i class="el-icon-arrow-down"></i></span>
                </div>
            </div>
        </div>
        <div class="center-iphone">
            <div class="top">
                <div class="left">
                    <div class="openNav" @click="navShow = !navShow">
                        <img v-if="navShow" src="@/assets/openNav.png" alt="">
                        <img v-if="!navShow" src="@/assets/closeNav.png" alt="">
                    </div>
                    <div class="header-logo-box">
                        <router-link to="/">
                            <img src="@/assets/logos/headerLogo.png" alt="">
                        </router-link>
                    </div>
                </div>
                <div class="header-connet-box">
                    <div class="network">
                        <img @click="showNetwork = !showNetwork" src="@/assets/logos/changeNetwork-blast.png" alt="">
                        <div class="closeNetworklist" v-if="showNetwork" @click="showNetwork = false"></div>
                        <div class="networklist" v-if="showNetwork">
                            <h1>Select a network</h1>
                            <div class="box">
                                <ul class="list">
                                    <li>
                                        <p>
                                            <img src="../assets/Header/blast.png" alt="">
                                            Blast Sepolia
                                        </p>
                                        <div class="select selectYes">
                                            <i class="el-icon-check"></i>
                                        </div>
                                    </li>
                                    <!-- <li @click="gozkSyncSepolia">
                                        <p>
                                            <img src="../assets/Header/zksync.png" alt="">
                                            zkSync Sepolia
                                        </p>
                                        <div class="select">
                                            <i class="el-icon-check"></i>
                                        </div>
                                    </li>
                                    <li style="display:none;">
                                        <p>
                                            <img src="../assets/Header/evmos.png" alt="">
                                            Evmos
                                        </p>
                                        <div class="select">
                                            <i class="el-icon-check"></i>
                                        </div>
                                    </li>
                                    <li style="display:none;">
                                        <p>
                                            <img src="../assets/Header/scroll.png" alt="">
                                            Scroll
                                        </p>
                                        <div class="select">
                                            <i class="el-icon-check"></i>
                                        </div>
                                    </li> -->
                                </ul>
                            </div>
                            <div class="box">
                                <p class="show" @click="networkBottomList = !networkBottomList"><span>Mainnet</span><i :class="!networkBottomList ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i></p>
                                <ul v-if="networkBottomList" class="list">
                                    <li @click="goBlastMainnet">
                                        <p>
                                            <img src="../assets/Header/blast.png" alt="">
                                            Blast 
                                        </p>
                                        <div class="select ">
                                            <i class="el-icon-check"></i>
                                        </div>
                                    </li>
                                    <!-- <li @click="goOneUrl">
                                        <p>
                                            <img src="../assets/Header/zksync.png" alt="">
                                            zkSync Era
                                        </p>
                                        <div class="select">
                                            <i class="el-icon-check"></i>
                                        </div>
                                    </li>
                                    <li @click="goOneUrl">
                                        <p>
                                            <img src="../assets/Header/evmos.png" alt="">
                                            Evmos
                                        </p>
                                        <div class="select">
                                            <i class="el-icon-check"></i>
                                        </div>
                                    </li>
                                    <li @click="goOneUrl">
                                        <p>
                                            <img src="../assets/Header/scroll.png" alt="">
                                            Scroll
                                        </p>
                                        <div class="select">
                                            <i class="el-icon-check"></i>
                                        </div>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="connect">
                        <span v-if="userAddress && walletNetwork != chainId" @click="headerMessageShow = true;messageType = 'switchWalletNetwork'">Switch</span>
                        <span v-if="userAddress == ''" @click="connect">CONNECT</span>
                        <span v-if="userAddress && walletNetwork == chainId" @click="showUserInfo">{{ filtersUsrAddr(userAddress) }} <i class="el-icon-arrow-down"></i></span>
                        <!-- <span v-if="userAddress == ''">CONNECT</span>
                        <span v-else >{{ filtersUsrAddr(userAddress) }}</span>
                        <span v-if="userAddress != ''"><i class="el-icon-arrow-down"></i></span> -->
                    </div>
                </div>
            </div>
            <div class="nav" :style="{transform: navShow ? 'translateX(0)' : 'translateX(-100%)'}" >
                <div class="navBg" @click="navShow = !navShow"></div>
                <ul class="navList">
                    <li @click="goSWAP" ><img src="@/assets/icons/swap.png" alt=""> SWAP</li>
                    <li @click="goPOOL"><img src="@/assets/icons/pool.png" alt=""> POOL</li>
                    <router-link tag="li" to="/Farm" @click.native="navShow = !navShow"><img src="@/assets/icons/farm.png" alt=""> FARM</router-link>
                    <li class="nftPages" @click="nftPageShow = !nftPageShow">
                        <p><img src="@/assets/icons/nft.png" alt=""> NFT <i :class="!nftPageShow ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i></p>
                        <ul v-if="nftPageShow">
                            <router-link tag="li" to="/Mint" @click.native="navShow = !navShow"><img src="@/assets/icons/mint.png" alt=""> MINT</router-link>
                            <router-link tag="li" to="/Staking" @click.native="navShow = !navShow"><img src="@/assets/icons/staking.png" alt=""> STAKING</router-link>
                            <router-link tag="li" to="/Marketplace" @click.native="navShow = !navShow"><img src="@/assets/icons/market.png" alt=""> MARKETPLACE</router-link>
                            <router-link tag="li" to="/MyNFT" @click.native="navShow = !navShow"><img src="@/assets/icons/mynft.png" alt=""> MY NFT</router-link>
                        </ul>
                    </li>
                    <router-link tag="li" to="/Spacebase" @click.native="navShow = !navShow"><img src="@/assets/icons/spacebase.png" alt=""> SPACEBASE</router-link>
                    <router-link tag="li" to="/xSPACE" @click.native="navShow = !navShow"><img src="@/assets/icons/xspace.png" alt=""> XSPACE</router-link>
                    <router-link tag="li" to="/Launchpad" @click.native="navShow = !navShow"><img src="@/assets/icons/launchpad.png" alt=""> LAUNCHPAD</router-link>
                    <router-link tag="li" to="/Bridge" @click.native="navShow = !navShow"><img src="@/assets/icons/bridge.png" alt=""> BRIDGE</router-link>
                    <router-link tag="li" to="/Points" @click.native="navShow = !navShow"><img src="@/assets/icons/points.png" alt=""> POINTS</router-link>
                </ul>
            </div>
        </div>
        
        <div class="headerMessageBox" v-if="headerMessageShow" @click="headerMessageShow = false;">
            <div class="connectBoxW">
                <div class="warp" @click.stop>
                    <div class="connectwarp" v-if="messageType == 'connect'">
                        <div class="titlebox">
                            <h1>
                                Connect
                                <i class="el-icon-close" @click="headerMessageShow = false;messageType = ''"></i>
                            </h1>
                            <p class="tip">Connect wallet in one click to start using SpaceFi</p>
                        </div>
                        <div class="walletBox">
                            <div :class="walletType == 'METAMASK' && userAddress ? 'bg' : ''" @click="connectwallet('METAMASK')">
                                <img src="../assets/Header/metamask.png" alt="">
                                <p>Metamask</p>
                            </div>
                            <div :class="walletType == 'WALLETCONNECT' && userAddress ? 'bg' : ''" @click="connectwallet('WALLETCONNECT')">
                                <img src="../assets/Header/walletconnect.png" alt="">
                                <p>WalletConnect</p>
                            </div>
                            <div :class="walletType == 'OKXWALLET' && userAddress ? 'bg' : ''" @click="connectwallet('OKXWALLET')">
                                <img src="../assets/Header/okx.png" alt="">
                                <p>OKX Wallet</p>
                            </div>
                            <div :class="walletType == 'BITGETWALLET' && userAddress ? 'bg' : ''" @click="connectwallet('BITGETWALLET')">
                                <img src="../assets/Header/bitget.png" alt="">
                                <p>Bitget Wallet</p>
                            </div>
                        </div>
                        <p class="tip" style="margin-top:30px;">View only permissions. we cannot do anuthing without your approval.</p>
                    </div>
                    <div class="switchWalletNetworkwarp" v-if="messageType == 'switchWalletNetwork'">
                        <div class="titlebox">
                            <h1>
                                <p @click="headerMessageShow = true;messageType = 'connect'"> <i class="el-icon-arrow-left"></i> Wallets</p>
                                <i class="el-icon-close" @click="headerMessageShow = false;messageType = ''"></i>
                            </h1>  
                        </div>
                        <div class="infoWarp">
                            <p>Your wallet is not on the chosen network</p>
                            <p class="tip">You have connected to a different network from {{chainName}}. Please click the button below to change it.</p>
                        </div>
                        <div class="btns">
                            <p v-if="switchNetworkLoading" class="loadign"><v-LoadingWarp :imgSize="1"></v-LoadingWarp></p>
                            <p v-else class="btn" @click="switchNetwork">Switch network</p>
                        </div>
                    </div>
                    <div class="userinfowarp" v-if="messageType == 'userinfo'">
                        <div class="titlebox">
                            <h1>
                                <p style="opacity:0;"> user</p>
                                <i class="el-icon-close" @click="headerMessageShow = false;messageType = ''"></i>
                            </h1>  
                        </div>
                        <div class="infoWarp">
                            <div>
                                <span id="metamaskIcon"></span>
                                <!-- <img src="../assets/Header/okx.png" alt=""> -->
                            </div>
                            <p class="usersddress">{{ filtersUsrAddr8(userAddress) }}</p>
                            <p class="active">
                                <span @click="copy"><i class="el-icon-copy-document"></i> Copy Address</span>
                                <a :href="'https://sepolia.scrollscan.com/address/'+userAddress" target="_block"><i class="el-icon-link"></i> View on {{chainName}}</a>
                            </p>
                        </div>
                        <div class="btns">
                            <p class="btn" @click="logout">Logout</p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

    </div>
</template>

<script>
import { metamaskIcon } from '../utils/userIcon.js'
import { serWCP } from '../utils/space.js'
import Web3 from 'web3';
export default {
    name: "Header",
    props: {
        headerType: 0, routerType: 0,
    },
    data () {
        return {
            walletType:'',
            walletNetwork:'',chainId:300,chainName:'',
            navShow:false,nftPageShow:false,
            userAddress: '',
            showNetwork:false,
            networkBottomList:false,
            headerMessageShow:false,messageType:'',
            switchNetworkLoading:false
        }
    },
    computed:{
        filtersUsrAddr(){
            return (str) => {
                var firstStr = str.substr(0,4);
                var lastStr = str.substr(38);
                var middleStr = '****';
                var tempStr = firstStr + middleStr + lastStr;
                return tempStr;
            }
        },
        filtersUsrAddr8(){
            return (str) => {
                var firstStr = str.substr(0,10);
                var lastStr = str.substr(34);
                var middleStr = '****';
                var tempStr = firstStr + middleStr + lastStr;
                return tempStr;
            }
        }
    },
    created () {
        this.walletType = localStorage.getItem('walletType') || ''
        this.walletNetwork = localStorage.getItem('walletNetwork') || ''
        this.chainId = localStorage.getItem('chainId')
        this.chainName = localStorage.getItem('chainName')
        this.userAddress = localStorage.getItem('userAddress') || ''
    },
    mounted () { 
        
        
    },
    methods: {
        goBlastMainnet(){
            window.open('https://blaspace.io','_self')
        },
        goOneUrl(){
            window.open('https://app.spacefi.io','_self')
        },
        gozkSyncSepolia(){
            window.open('https://zksync-testnet.spacefi.io/','_self')
        },
        connect(){
            if (!this.userAddress) {
                //console.log(1)
                this.headerMessageShow = true
                this.messageType = 'connect'
            }
            // window.ethereum.request({
            //     method:'eth_requestAccounts'
            // }).then(res => {
            //     this.userAddress = res[0];
            //     localStorage.setItem('userAddress',res[0]);
            //     window.location.reload()
            // }).catch(err => {
                
            // })
        },
        connectwallet(walletType){
            localStorage.setItem('walletType',walletType)
            this.headerMessageShow = false
            this.messageType = ''
            if (walletType == 'METAMASK') {
                this.walletNetwork = localStorage.getItem('walletNetwork') || ''
                window.ethereum.request({
                    method:'eth_requestAccounts'
                }).then(res => {
                    this.userAddress = res[0]
                    localStorage.setItem('userAddress',res[0]);
                    window.ethereum.request({method:'net_version'}).then(res => {
                        if (Web3.utils.toHex(res) == localStorage.getItem('chainId')) {
                            window.location.reload()
                        } else {
                            window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [{
                                    'chainId': localStorage.getItem('chainId'),
                                    'chainName': localStorage.getItem('chainName'),
                                    'nativeCurrency': {
                                        'name': localStorage.getItem('nativeCurrencyName'),
                                        'symbol': localStorage.getItem('nativeCurrencySymbol'),
                                        'decimals':18,
                                    },
                                    'rpcUrls': JSON.parse(localStorage.getItem('rpcUrls')),
                                    'blockExplorerUrls': JSON.parse(localStorage.getItem('blockExplorerUrls')),
                                }],
                            }).then((success) => {
                                window.ethereum.request({method:'net_version'}).then(res => {
                                    //console.log(Web3.utils.toHex(res) , localStorage.getItem('chainId'))
                                    if (Web3.utils.toHex(res) == localStorage.getItem('chainId')) {
                                        //console.log(1)
                                        localStorage.setItem('walletNetwork',Web3.utils.toHex(res))
                                        window.location.reload()
                                    }
                                })
                            },error => {
                                this.$notify({type:'warning',message:'Please connect wallet first!'})
                            }).catch(function(err) {
                                this.$notify({type:'warning',message:'Please connect wallet first!'})
                            })
                        }
                    })
                    
                }).catch(err => {
                    
                })
            }
            if (walletType == 'OKXWALLET') {
                if ( typeof window.okxwallet != "undefined" ) {
                    // return
                    window.okxwallet.request({
                        method:'eth_requestAccounts'
                    }).then(res => {
                        this.userAddress = res[0]
                        localStorage.setItem('userAddress',res[0]);
                        window.location.reload()
                    }).catch(err => {
                    })
                } else {
                    this.$notify({type:'warning',message:'OKX Wallet is not installed'})
                }
            }
            if (walletType == 'BITGETWALLET') {
                if ( typeof window.bitkeep != "undefined" ) {
                    // return
                    window.bitkeep.ethereum.request({
                        method:'eth_requestAccounts'
                    }).then(res => {
                        this.userAddress = res[0]
                        localStorage.setItem('userAddress',res[0]);
                        window.location.reload()
                    }).catch(err => {
                        //console.log(err)
                    })
                } else {
                    this.$notify({type:'warning',message:'Bitget Wallet is not installed'})
                    window.open('https://web3.bitget.com','_blank')
                }
            }
            if (walletType == 'WALLETCONNECT') {
                // serWCP()
            }
        },
        switchNetwork(){
            this.switchNetworkLoading = true
            window.ethereum.request({method:'net_version'}).then(res => {
                if (Web3.utils.toHex(res) != localStorage.getItem('chainId')) {
                    window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            'chainId': Web3.utils.toHex(localStorage.getItem('chainId')),
                            'chainName': localStorage.getItem('chainName'),
                            'nativeCurrency': {
                                'name': localStorage.getItem('nativeCurrencyName'),
                                'symbol': localStorage.getItem('nativeCurrencySymbol'),
                                'decimals':18,
                            },
                            'rpcUrls': JSON.parse(localStorage.getItem('rpcUrls')),
                            'blockExplorerUrls': JSON.parse(localStorage.getItem('blockExplorerUrls')),
                        }],
                    }).then((success) => {
                        this.getwalletnetwork()
                    },error => {
                        this.$notify({type:'warning',message:'Please connect wallet first!'})
                    }).catch(function(err) {
                        this.$notify({type:'warning',message:'Please connect wallet first!'})
                    })
                }
            });
        },
        getwalletnetwork(){
            this.switchNetworkLoading = false
            window.ethereum.request({method:'net_version'}).then(res => {
                //console.log(res)
                localStorage.setItem('walletNetwork',res)
                if (Web3.utils.toHex(res) == localStorage.getItem('chainId')) {
                    this.headerMessageShow = false
                    this.messageType = ''
                    this.walletNetwork = Web3.utils.toHex(res)
                    // window.location.reload()
                }
            })
        },
        showUserInfo(){
            this.headerMessageShow = true;
            this.messageType = 'userinfo'
            metamaskIcon(this.userAddress,40).then(metamaskIconRes => {
                //console.log(document.getElementById('metamaskIcon'))
                document.getElementById('metamaskIcon').appendChild(metamaskIconRes);
            })
        },
        copy(){
            var oInput = document.createElement('input');
            oInput.value = this.userAddress;
            document.body.appendChild(oInput);
            oInput.select();
            document.execCommand("Copy");
            oInput.className = 'oInput';
            oInput.style.display='none';
            // this.$message({ type: 'success', message: 'Copy successfully' });
        },
        logout(){
            this.headerMessageShow = false
            this.messageType = ''
            this.walletType = ''
            localStorage.setItem('walletType','')
            this.userAddress = ''
            localStorage.setItem('userAddress','')
            window.location.reload()
        },
        goSWAP () {
            window.open("https://swap-testnet.blaspace.io/#/swap","_self")
        },
        goPOOL () {
            window.open("https://swap-testnet.blaspace.io/#/pool","_self")
        }
    }
}
</script>