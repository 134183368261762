<template>
    <div class="Footer">
        <div class="homePage6">
            <div class="page6Bg">
                <img src="@/assets/Home/plantBottom.png" alt="" srcset="">
            </div>
            <div class="page6Box connectBoxW">
                <div>
                    <div class="boxLeft">
                        <div class="footer-logo-box">
                            <router-link to="/">
                                <img src="@/assets/logos/headerLogo.png" alt="">
                            </router-link>
                        </div>
                        <p>Blaspace is the DeFi hub on Blast with DEX+NFT+Spacebase+Launchpad, exploring the Layer2 ecosystem.</p>
                        <ul class="linksList">
                            <li class="externalLink"><a href="https://twitter.com/blaspace_io" target="_block"><span></span></a></li>
                            <li class="externalLink"><a href="https://discord.gg/nezjCa5j8r" target="_block"><span></span></a></li>
                            <li class="externalLink"><a href="https://www.youtube.com/channel/UCg_vqttyVABAfhtfZJOPykg" target="_block"><span></span></a></li>
                            <li class="externalLink"><a href="https://medium.com/@BlaSpace" target="_block"><span></span></a></li>
                            <li class="externalLink"><a href="https://docs.spacefi.io/" target="_block"><span></span></a></li>
                        </ul>
                    </div>
                    <div class="boxRight">
                        <ul>
                            <li><a href="" onclick="return false;">PRODUCTS</a></li>
                            <li><a href="https://swap-testnet.blaspace.io/#/swap" target="_self">Swap</a></li>
                            <li><a href="https://swap-testnet.blaspace.io/#/pool" target="_self">Pool</a></li>
                            <li><router-link to="/Farm" tag="a">Farm</router-link></li>
                            <li><router-link to="/Mint" tag="a">NFT</router-link></li>
                            <li><router-link to="/Spacebase" tag="a">Spacebase</router-link></li>
                            <li><router-link to="/xSPACE" tag="a">xSPACE</router-link></li>
                            <li><router-link to="/Launchpad" tag="a">Launchpad</router-link></li>
                            <li><router-link to="/Bridge" tag="a">Bridge</router-link></li>
                            <li><router-link to="/Points" tag="a">Points</router-link></li>
                        </ul>
                        <ul>
                            <li><a href="" onclick="return false;">COMMUNITY</a></li>
                            <li><a href="https://twitter.com/blaspace_io" target="_block">Twitter</a></li>
                            <li><a href="https://discord.gg/nezjCa5j8r" target="_block">Discord</a></li>
                            <li><a href="https://www.youtube.com/channel/UCg_vqttyVABAfhtfZJOPykg" target="_block">YouTube</a></li>
                            <li><a href="https://medium.com/@BlaSpace" target="_block">Medium</a></li>
                        </ul>
                        <ul>
                            <li><a href="" onclick="return false;">SUPPORT</a></li>
                            <li><a href="https://docs.spacefi.io/" target="_block">Docs</a></li>
                            <li><a href="https://drive.google.com/drive/folders/19CesXs5J8jv8GY3q-BmA5utbSQUPlD9g" target="_block">Media Kit</a></li>
                            <li><a href="https://dexscreener.com/zksync/spacefi" target="_block">DEX Screener</a></li>
                            <li><a href="https://www.geckoterminal.com/zksync/spacefi_zksync/pools" target="_block">GeckoTerminal</a></li>
                            <li><a href="https://www.coingecko.com/en/coins/spacefi-evmos" target="_block">CoinGecko</a></li>
                            <li><a href="https://coinmarketcap.com/currencies/spacefi-evmos/" target="_block">CoinMarketCap</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer",
    props: {
        
    },
    data() {
        return {

        }
    },
    created() { },
    mounted() { },
    methods: {

    }
}
</script>