import Web3 from 'web3';
import {getBalanceOf, gettotalSupply} from './space.js'
import BigNumber from 'bignumber.js'
var farmData = window.farmData.lpPools
var tokensList =  window.farmData.tokenList
var NewFarm =  window.farmData.singlePools.concat(window.farmData.lpPools)

farmData.forEach((item,index) => {
    if (item.coin.tokenB.contract == tokensList[0].contract) {
        getBalanceOf(item.coin.tokenA.contract,item.coin.contract).then(balanceA => {
            var lpANum = BigNumber(balanceA).div(Math.pow(10,item.coin.tokenA.decimals))
            getBalanceOf(item.coin.tokenB.contract,item.coin.contract).then(balanceB => {
                var lpBNum = BigNumber(balanceB).div(Math.pow(10,item.coin.tokenB.decimals))
                tokensList.forEach((tokenItem,tokenIndex) => {
                    if (tokenItem.contract == item.coin.tokenA.contract) {
                        tokenItem.price = BigNumber(lpBNum).div(lpANum).times(tokensList[0].price).toString()
                        window.tokenList = tokensList
                    }
                })
            })
        })
    }
});
setTimeout(() => {
    tokensList.forEach((tokenItem,tokenIndex) => {
        farmData.forEach((farmItem,farmIndex) => {
            if (tokenItem.contract == farmItem.coin.tokenA.contract && tokenItem.price) {
                tokensList.forEach((tokenItem1,tokenIndex) => {
                    if (farmItem.coin.tokenB.contract == tokenItem1.contract && !tokenItem1.price) {
                        getBalanceOf(farmItem.coin.tokenA.contract,farmItem.coin.contract).then(balanceA => {
                            var lpANum = BigNumber(balanceA).div(Math.pow(10,farmItem.coin.tokenA.decimals))
                            getBalanceOf(farmItem.coin.tokenB.contract,farmItem.coin.contract).then(balanceB => {
                                var lpBNum = BigNumber(balanceB).div(Math.pow(10,farmItem.coin.tokenB.decimals))
                                // tokenItem1.price = _BigNumber(lpANum).times(tokenItem.price).div(lpBNum)
                                tokenItem1.price = lpANum*tokenItem.price/lpBNum
                                window.tokenList = tokensList
                            })
                        })
                    }
                })
            }
        })
    })
}, 2000);

setTimeout(() => {
    NewFarm.forEach((item,index) => {
        for (let i = 0; i < tokensList.length; i++) {
            if (item.coin.tokenA) {
                getgettotalSupply(item.coin.contract).then(totalAll => {
                    var _totalAll =  BigNumber(totalAll).div(Math.pow(10,item.coin.decimals))
                    getBalanceOf(item.coin.tokenA.contract,item.coin.contract).then(lpAB => {
                        tokensList.forEach((itemA,indexA) => {
                            if(itemA.contract == item.coin.tokenA.contract){
                                var _lpAB = BigNumber(lpAB).div(Math.pow(10,item.coin.tokenA.decimals)).times(itemA.price)
                                getBalanceOf(item.coin.tokenB.contract,item.coin.contract).then(lpBB => {
                                    tokensList.forEach((itemB,indexB) => {
                                        if(itemB.contract == item.coin.tokenB.contract){
                                            var _lpBB = BigNumber(lpBB).div((Math.pow(10,item.coin.tokenB.decimals))).times(itemB.price)
                                            item.price = BigNumber(_lpAB).plus(_lpBB).div(_totalAll).toString()
                                            
                                        }
                                    })
                                })
                            }
                        })
                    })
                })
            } else {
                if ( item.coin.tokenAddress == tokensList[i].contract || item.coin.contract == tokensList[i].contract) {
                    item.price = tokensList[i].price
                    // window.farmData.singlePools.forEach(item => {
                    //     //console.log(item)
                    // })
                }
            }
        }
    })
    window.newFarmData = NewFarm
},3000)


function getgettotalSupply(addr1){
    return new Promise((resolve, reject) => {
        gettotalSupply(addr1).then(res => {
            resolve( res)
        })
    })
}