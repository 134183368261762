import Vue from 'vue';
import Web3 from 'web3';
import tokenABI from '@/contracts/tokenABI.json';
import xTokenABI from '@/contracts/xTokenABI.json';
import devABI from '@/contracts/devABI.json';
import farmABI from '@/contracts/farmABI.json';
import farmLibABI from '@/contracts/farmLibABI.json';
import farmPendingABI from '@/contracts/farmPendingABI.json';
import publicABI from '@/contracts/publicABI.json';
import privateABI from '@/contracts/privateABI.json';
import nodeABI from '@/contracts/nodeABI.json';
import nftABI from '@/contracts/nftABI.json';
import nftLogicABI from '@/contracts/nftLogicABI.json';
import nftMarketABI from '@/contracts/nftMarketABI.json';
import migrateABI from '@/contracts/migrateABI.json';

// Vue.prototype.$spaceWeb3 = new Web3(new Web3.providers.HttpProvider("https://rpc.ankr.com/blast_testnet_sepolia"))
Vue.prototype.$spaceWeb3 = new Web3(new Web3.providers.HttpProvider("https://rpc.ankr.com/blast_testnet_sepolia/33c3f27086d6ad7e0fd08a35145207a0032c718831d8bcf174cfcbb1ba355d31"))
Vue.prototype.$sendWeb3 = new Web3(window.ethereum);
export function setTokenContracts(contractAddr,rw){
    return new Promise((resolve, reject) => {
        var type = sessionStorage.getItem('type')
        if(!type) type='';
        var contract;
        if ('call' == rw) {
            if(type=='local'){
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(tokenABI,contractAddr)
            }else{
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(tokenABI,contractAddr)
            }
        } else {
            contract = new Vue.prototype.$sendWeb3.eth.Contract(tokenABI,contractAddr)
        }
        resolve(contract);
    })
}
export function setxTokenContracts(contractAddr,rw){
    return new Promise((resolve, reject) => {
        var type = sessionStorage.getItem('type')
        if(!type) type='';
        var contract;
        if ('call' == rw) {
            if(type=='local'){
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(xTokenABI,contractAddr)
            }else{
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(xTokenABI,contractAddr)
            }
        } else {
            contract = new Vue.prototype.$sendWeb3.eth.Contract(xTokenABI,contractAddr)
        }
        resolve(contract)
    })
}
export function setDevContracts(contractAddr,rw){
    return new Promise((resolve, reject) => {
        var type = sessionStorage.getItem('type')
        if(!type) type='';
        var contract;
        if ('call' == rw) {
            if(type=='local'){
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(devABI,contractAddr)
            }else{
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(devABI,contractAddr)
            }
        } else {
            contract = new Vue.prototype.$sendWeb3.eth.Contract(devABI,contractAddr)
        }
        resolve(contract)
    })
}
export function setFarmContracts(contractAddr,rw){
    return new Promise((resolve, reject) => {
        var type = sessionStorage.getItem('type')
        if(!type) type='';
        var contract;
        if ('call' == rw) {
            if(type=='local'){
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(farmABI,contractAddr)
            }else{
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(farmABI,contractAddr)
            }
        } else {
            contract = new Vue.prototype.$sendWeb3.eth.Contract(farmABI,contractAddr)
        }
        resolve(contract)
    })
}
export function setFarmLibContracts(contractAddr,rw){
    return new Promise((resolve, reject) => {
        var type = sessionStorage.getItem('type')
        if(!type) type='';
        var contract;
        if ('call' == rw) {
            if(type=='local'){
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(farmLibABI,contractAddr)
            }else{
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(farmLibABI,contractAddr)
            }
        } else {
            contract = new Vue.prototype.$sendWeb3.eth.Contract(farmLibABI,contractAddr)
        }
        resolve(contract)
    })
}
export function setFarmPendingABIContracts(contractAddr,rw){
    return new Promise((resolve, reject) => {
        var type = sessionStorage.getItem('type')
        if(!type) type='';
        var contract;
        if ('call' == rw) {
            if(type=='local'){
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(farmPendingABI,contractAddr)
            }else{
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(farmPendingABI,contractAddr)
            }
        } else {
            contract = new Vue.prototype.$sendWeb3.eth.Contract(farmPendingABI,contractAddr)
        }
        resolve(contract)
    })
}
export function setStarterContracts(contractAddr,rw,a,accessType){
    return new Promise((resolve, reject) => {
        var type = sessionStorage.getItem('type')
        if(!type) type='';
        var starterABI = accessType == 0 ? publicABI : privateABI
        var contract;
        if ('call' == rw) {
            if(type=='local'){
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(starterABI,contractAddr)
            }else{
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(starterABI,contractAddr)
            }
        } else {
            contract = new Vue.prototype.$sendWeb3.eth.Contract(starterABI,contractAddr)
        }
        resolve(contract)
    })
}
export function setNodeContracts(contractAddr,rw){
    return new Promise((resolve, reject) => {
        var type = sessionStorage.getItem('type')
        if(!type) type='';
        var contract;
        if ('call' == rw) {
            if(type=='local'){
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(nodeABI,contractAddr)
            }else{
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(nodeABI,contractAddr)
            }
        } else {
            contract = new Vue.prototype.$sendWeb3.eth.Contract(nodeABI,contractAddr)
        }
        resolve(contract)
    })
}
export function setnftContracts(contractAddr,rw){
    return new Promise((resolve, reject) => {
        var type = sessionStorage.getItem('type')
        if(!type) type='';
        var contract;
        if ('call' == rw) {
            if(type=='local'){
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(nftABI,contractAddr)
            }else{
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(nftABI,contractAddr)
            }
        } else {
            contract = new Vue.prototype.$sendWeb3.eth.Contract(nftABI,contractAddr)
        }
        resolve(contract)
    })
}
export function setnftLogicContracts(contractAddr,rw){
    return new Promise((resolve, reject) => {
        var type = sessionStorage.getItem('type')
        if(!type) type='';
        var contract;
        if ('call' == rw) {
            if(type=='local'){
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(nftLogicABI,contractAddr)
            }else{
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(nftLogicABI,contractAddr)
            }
        } else {
            contract = new Vue.prototype.$sendWeb3.eth.Contract(nftLogicABI,contractAddr)
        }
        resolve(contract)
    })
}
export function setnftMarketContracts(contractAddr,rw){
    return new Promise((resolve, reject) => {
        var type = sessionStorage.getItem('type')
        if(!type) type='';
        var contract;
        if ('call' == rw) {
            if(type=='local'){
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(nftMarketABI,contractAddr)
            }else{
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(nftMarketABI,contractAddr)
            }
        } else {
            contract = new Vue.prototype.$sendWeb3.eth.Contract(nftMarketABI,contractAddr)
        }
        resolve(contract)
    })
}
export function setMigrateContracts(contractAddr,rw){
    return new Promise((resolve, reject) => {
        var type = sessionStorage.getItem('type')
        if(!type) type='';
        var contract;
        if ('call' == rw) {
            if(type=='local'){
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(migrateABI,contractAddr)
            }else{
                contract = new Vue.prototype.$spaceWeb3.eth.Contract(migrateABI,contractAddr)
            }
        } else {
            contract = new Vue.prototype.$sendWeb3.eth.Contract(migrateABI,contractAddr)
        }
        resolve(contract)
    })
}

export function transactionSend (from,contractAddr,methods,value){
    //console.log(methods.encodeABI())
    return new Promise((resolve,reject) => {
        Vue.prototype.$spaceWeb3.eth.estimateGas({
            from:from,
            to: contractAddr,
            value: value ? Web3.utils.toHex(value) : 0,
            data: methods.encodeABI()
        }).then(res => {
            //console.log('1',res,localStorage.getItem('chainId'),value)
            var obj = {
                from: from,
                to: contractAddr,
                value: value ? Web3.utils.toHex(value) : 0,
                data: methods.encodeABI(),
                // maxFeePerGas:Vue.prototype.$sendWeb3.utils.toHex(res+250000000),
                // maxPriorityFeePerGas:Vue.prototype.$sendWeb3.utils.toHex(250000000),
                // gas:Vue.prototype.$sendWeb3.utils.toHex(res),
                // gasPrice:Vue.prototype.$sendWeb3.utils.toHex(250000000),
                // gasLimit:Vue.prototype.$sendWeb3.utils.toHex(res),
            }
            //console.log('....')
            Vue.prototype.$sendWeb3.eth.getTransactionCount(from).then(beforCount => {
                //console.log('2',beforCount)
                var count = beforCount+1
                Vue.prototype.$sendWeb3.eth.sendTransaction(obj).then(resHash => {
                    //console.log('3',resHash)
                    var estimateGasNum = resHash.gasUsed*resHash.effectiveGasPrice
                    var transactionLogs = resHash.logs
                    var userAddress = from
                    var transactionFee = 0
                    transactionLogs.forEach((item ,index) => {
                        if( item.topics[2] && item.topics[1].slice(item.topics[1].length-8).toLocaleUpperCase() == userAddress.slice(userAddress.length-8).toLocaleUpperCase() && item.topics[2].slice(item.topics[2].length-4).toLocaleUpperCase() == '8001' ){
                            transactionFee += Number(Vue.prototype.$spaceWeb3.utils.toBN(item.data).toString())
                        }
                    })
                    var timer = setInterval(() => {
                        Vue.prototype.$sendWeb3.eth.getTransactionCount(from).then(afterCount => {
                            if(count == afterCount) {
                                resolve({
                                    hash:resHash.transactionHash,
                                    refundGasNum:Number((transactionFee/1e18)-(estimateGasNum/1e18)),
                                    refundRatio:Number((1-((estimateGasNum/1e18)/(transactionFee/1e18)))*100).toFixed(2)
                                })
                                clearTimeout(timer)
                            }
                        })
                    },500)
                }).catch(err => {
                    //console.log('33',err)
                    reject(err)
                })
            }).catch(err => {
                //console.log('22',err)
                reject(err)
            })
        }).catch(err => {
            //console.log('11',err)
            reject(err)
        })
    })
}